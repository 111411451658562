import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ConfigService } from 'app/core/services/config.service';
import { EntryDataService } from 'app/core/services/entry-data.service';

import { StateService } from '../../core/services/state.service';

@Component({
  selector: 'cc-chat-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private stateService: StateService,
    private entryDataService: EntryDataService,
    public configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.prepareForm();
    const script = document.currentScript;
    console.log(script);
  }

  prepareForm(): void {
    this.form = new UntypedFormGroup({});
  }

  onSubmit(): void {
    const entryData = {
      nickname: this.form.value.nickname,
      email: this.form.value.email,
      phone: this.form.value.phone,
    };
    const contactInfo = this.form?.value?.contact;
    this.entryDataService.initializeChatWithEntryData(entryData, contactInfo);
  }

  close(): void {
    if (
      (this.configService.getHidden() != undefined &&
        this.configService.getShowonclose() == undefined) ||
      this.configService.getShowonclose() == false
    ) {
      this.configService.setHidden(true);
    } else {
      this.stateService.setToMinimized();
    }
  }
}
