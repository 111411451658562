<div
  class="logate-chat-header"
  [style.font-family]="(configService.config$ | async)?.fontFamily"
>
  <div class="logate-chat-header__operator">
    <div
      [ngStyle]="(configService.config$ | async)?.operatorLogoCss"
      class="logate-chat-header__operator--icon"
    ></div>
    <div>
      <div class="logate-chat-header__operator--title">
        {{ typingEventService.operatorName$ | async }}
      </div>
    </div>
  </div>

  <div class="logate-chat-header__actions">
    <div
      class="logate-chat-header__icon logate-chat-header__icon--minimise"
      (click)="minimize()"
    ></div>
    <div
      class="logate-chat-header__icon logate-chat-header__icon--close"
      (click)="close()"
    ></div>
  </div>
</div>
