import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ConfigService } from 'app/core/services/config.service';
import { CustomerExperienceItem } from '../models/customer-experience-item.model';

@Component({
  selector: 'cc-chat-customer-experience-satisfaction',
  templateUrl: './customer-experience-satisfaction.component.html',
  styleUrls: ['./customer-experience-satisfaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerExperienceSatisfactionComponent implements OnInit {
  @Input() multi: boolean;
  @Input() items: CustomerExperienceItem[];

  selectedIndexes: number[] = [];

  constructor(public configService: ConfigService) {}

  ngOnInit(): void {}

  onSelect(index: number): void {
    if (!this.multi) {
      this.selectedIndexes[0] = index;
      return;
    }

    const selectedIndex = this.findByIndex(index);

    if (selectedIndex > -1) {
      this.removeItem(selectedIndex);
    } else {
      this.addItem(index);
    }
  }

  isSelected(index: number): boolean {
    return this.findByIndex(index) > -1;
  }

  private removeItem(index: number): void {
    this.selectedIndexes.splice(index, 1);
  }

  private addItem(index): void {
    this.selectedIndexes.push(index);
  }

  private findByIndex(index: number): number {
    return this.selectedIndexes.findIndex((item) => item === index);
  }
}
