import { DateParser } from '../../../core/parsers/date.parser';
import { InteractionEventType } from './interaction-event-type';
import * as moment from 'moment';

export class Message {
  externalId?: string;
  direction: 'IN' | 'OUT';
  content: string;
  date: Date;
  deliveryStatus?: InteractionEventType;

  constructor(init?: Partial<Message>) {
    Object.assign(this, init);
  }

  getFormattedDate() {
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      this.date
    );
    const month = DateParser.getMonth(this.date.getMonth());
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      this.date
    );
    const hour = new Intl.DateTimeFormat('en', {
      hour: '2-digit',
      hour12: false,
    }).format(this.date);
    const minutes = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(
      this.date
    );
    return `${month} ${day}. ${year}. u ${hour}:${
      minutes.length === 2 ? minutes : '0' + minutes
    }`;
  }

  getFormattedTime() {
    return moment.utc(this.date).local().format('HH: mm');
  }
}
