import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'app/core/services/config.service';

@Component({
  selector: 'cc-chat-customer-experience-submit',
  templateUrl: './customer-experience-submit.component.html',
  styleUrls: ['./customer-experience-submit.component.scss'],
})
export class CustomerExperienceSubmitComponent implements OnInit {
  constructor(public configService: ConfigService) {}

  ngOnInit(): void {}
}
