<div
  class="logate-chat-customer-experience-description"
  [style.font-family]="(configService.config$ | async)?.fontFamily"
>
  <h1 class="logate-chat-customer-experience-description__title">
    <span class="logate-chat-customer-experience-description__icon"></span>
    LOGATE
  </h1>

  <h3 class="logate-chat-customer-experience-description__subtitle">
    {{ "customer-experience.description.subtitle" | translate }}
  </h3>
  <p class="logate-chat-customer-experience-description__content">
    {{ "customer-experience.description.content0" | translate }}
  </p>
  <p class="logate-chat-customer-experience-description__content">
    {{ "customer-experience.description.content1" | translate }}
  </p>
  <p class="logate-chat-customer-experience-description__content">
    {{ "customer-experience.description.content2" | translate }}
  </p>
</div>
