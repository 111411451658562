<div class="logate-chat-interaction-unread">
  <div class="logate-chat-interaction-unread--line"></div>
  <div
    class="logate-chat-interaction-unread--content"
    [style.font-family]="(configService.config$ | async)?.fontFamily"
  >
    Nove poruke
  </div>
  <div class="logate-chat-interaction-unread--line"></div>
</div>
