import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ConfigService } from 'app/core/services/config.service';
import { MessageService } from 'app/core/services/message.service';
import { InteractionEventType } from '../../models/interaction-event-type';
import { Message } from '../../models/message.model';

@Component({
  selector: 'cc-chat-interaction-message',
  templateUrl: './interaction-message.component.html',
  styleUrls: ['./interaction-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractionMessageComponent implements OnInit {
  @Input() message: Message;
  @Input() deliveryStatus: InteractionEventType;

  InteractionEventType = InteractionEventType;
  constructor(
    public configService: ConfigService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  resendMessage() {
    this.messageService.resendMessage(this.message);
  }
}
