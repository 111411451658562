<div
  class="logate-chat-wrapper"
  [style.background-color]="
    (configService.config$ | async)?.interactionMessagesBackground
  "
>
  <div class="logate-chat-finish-dialog">
    <div class="logate-chat-smiley"></div>
    <div
      class="logate-chat-title"
      [style.font-family]="(configService.config$ | async)?.fontFamily"
    >
      {{ title | translate }}
    </div>
    <div
      class="logate-chat-subtitle"
      [style.font-family]="(configService.config$ | async)?.fontFamily"
    >
      {{ subtitle | translate }}
    </div>
    <button
      class="logate-chat-button logate-chat-button__grey"
      [style.font-family]="(configService.config$ | async)?.fontFamily"
      (click)="backToOldChat()"
    >
      {{ "interaction.ended.back-to-old" | translate }}
    </button>
    <button
      class="logate-chat-button logate-chat-button__green"
      [style.font-family]="(configService.config$ | async)?.fontFamily"
      (click)="startChatAgain()"
    >
      {{ "interaction.ended.begin-new" | translate }}
    </button>
  </div>
</div>
