import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Message } from '../models/message.model';
import { MessageService } from '../../../core/services/message.service';
import { TypingEventService } from '../services/typing-event.service';
import { openCloseAnimation } from '../../../core/animations/openCloseAnimation';
import { Subject } from 'rxjs';
import { ContactService } from '../../../core/services/contact.service';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'cc-chat-interaction-form',
  templateUrl: './interaction-form.component.html',
  styleUrls: ['./interaction-form.component.scss'],
  animations: [...openCloseAnimation],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class InteractionFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  isEmojiPickerVisible = false;
  messageNumber: number;

  destroy$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private contactService: ContactService,
    private typingEventService: TypingEventService
  ) {}

  ngOnInit(): void {
    this.prepareForm();
    this.watchForMessageChanges();
    this.watchForMessageNumber();
  }

  prepareForm(): void {
    this.form = new UntypedFormGroup({
      message: new UntypedFormControl('', Validators.required),
    });
  }

  sendMessage(): void {
    const content = this.message.value;
    const message = new Message({
      externalId: uuid(),
      content,
      direction: 'IN',
      date: new Date(),
    });

    this.messageService.sendMessage(message);
    this.form.reset();
    this.isEmojiPickerVisible = false;
  }

  addEmoji(event): void {
    const value = (this.message.value ?? '') + event.emoji.native;

    this.message.setValue(value);
    this.isEmojiPickerVisible = false;
  }

  toggleIsEmojiPickerVisible(): void {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }

  // When user start typing. We are sending event for typing
  private watchForMessageChanges(): void {
    this.message.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.publishEventTyping();
    });
  }

  private publishEventTyping(): void {
    this.typingEventService.publishTyping();
  }

  private watchForMessageNumber(): void {
    this.messageService
      .getNumberOfMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.messageNumber = value));
  }

  private getContactInfoForFirstMessage(): string {
    if (this.messageNumber || this.messageNumber > 1) {
      return '';
    }

    return this.contactService.parseContactInfo();
  }

  get message(): AbstractControl {
    return this.form.get('message');
  }

  enterClicked(event) {
    // Enter was pressed without shift key
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault(); // Stops enter from creating a new line
      this.sendMessage();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
