<div
  class="logate-chat-interaction-messages"
  [style.background-color]="
    (configService.config$ | async)?.interactionMessagesBackground
  "
  #messagesElement
>
  <ng-container
    *ngFor="
      let message of messages$ | async as messages;
      let i = index;
      let first = first
    "
  >
    <div class="logate-chat-datetime" *ngIf="first">
      {{ message.getFormattedDate() }}
    </div>
    <ng-container
      *ngIf="unreadMessageService.unreadMessages$ | async as unreadMessages"
    >
      <cc-chat-interaction-unread-line
        *ngIf="messages.length - unreadMessages === i"
      ></cc-chat-interaction-unread-line>
    </ng-container>

    <cc-chat-interaction-message
      [message]="message"
      [deliveryStatus]="message.deliveryStatus"
      [ngClass]="'logate-chat-interaction-messages__' + message.direction"
    >
    </cc-chat-interaction-message>
  </ng-container>
</div>
