import { Component, OnDestroy, OnInit } from '@angular/core';
import { InteractionState } from 'app/chat/interaction/models/interaction-state';
import { InteractionStateService } from 'app/chat/interaction/services/interaction-state.service';
import { TypingEventService } from 'app/chat/interaction/services/typing-event.service';
import { ConfigService } from 'app/core/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { State } from '../../enums/state.enum';
import { MessageService } from '../../services/message.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'cc-chat-operator-header',
  templateUrl: './operator-header.component.html',
  styleUrls: ['./operator-header.component.scss'],
})
export class OperatorHeaderComponent implements OnInit, OnDestroy {
  numberOfMessages: number;
  destroy$ = new Subject<void>();
  currentState: State;

  constructor(
    private stateService: StateService,
    public interactionStateService: InteractionStateService,
    private messageService: MessageService,
    public typingEventService: TypingEventService,
    public configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.watchForNumberOfMessages();
    this.watchForCurrentState();
    this.typingEventService
      .initWatcher()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  close(): void {
    if (this.interactionStateService.getState() === InteractionState.FINISHED) {
      this.minimize();
    } else if (
      this.interactionStateService.getState() === InteractionState.DISABLED_CHAT
    ) {
      this.interactionStateService.setToFinished();
    } else {
      this.interactionStateService.setToConfirmationFinish();
    }
  }

  minimize(): void {
    if (
      (this.configService.getHidden() != undefined &&
        this.configService.getShowonclose() == undefined) ||
      this.configService.getShowonclose() == false
    ) {
      this.stateService.setToMinimizedHidden();
    } else {
      this.stateService.setToMinimized();
    }
  }

  watchForNumberOfMessages(): void {
    this.messageService
      .getNumberOfMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.numberOfMessages = value));
  }

  updateState(numberOfMessages: number): void {
    // This is when have customer experience

    // if (numberOfMessages && this.currentState !== State.CUSTOMER_EXPERIENCE) {
    //   this.stateService.setToCustomerExperience();
    // } else {
    // }

    this.messageService.resetMessages();
    this.stateService.resetState();
  }

  watchForCurrentState(): void {
    this.stateService
      .getState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.currentState = value));
  }

  ngOnDestroy(): void {
    if (this.destroy$) {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }
}
