import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'app/core/services/config.service';

@Component({
  selector: 'cc-chat-interaction-unread-line',
  templateUrl: './interaction-unread-line.component.html',
  styleUrls: ['./interaction-unread-line.component.scss'],
})
export class InteractionUnreadLineComponent implements OnInit {
  constructor(public configService: ConfigService) {}

  ngOnInit(): void {}
}
