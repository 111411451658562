<form
  class="logate-chat-interaction-form"
  [formGroup]="form"
  (ngSubmit)="form.valid && sendMessage()"
>
  <label class="logate-chat-interaction-form__field">
    <span
      class="logate-chat-interaction-form__field--icon"
      [ngClass]="{
        'logate-chat-interaction-form__field--icon-active':
          isEmojiPickerVisible,
        'logate-chat-interaction-form__field--icon-inactive':
          !isEmojiPickerVisible
      }"
      (click)="toggleIsEmojiPickerVisible()"
    ></span>
    <textarea
      autosize
      type="text"
      class="logate-chat-interaction-form__field--input"
      (keydown.enter)="enterClicked($event)"
      formControlName="message"
      placeholder="{{ 'interaction.form.write-here' | translate }}"
    ></textarea>
  </label>

  <button
    type="submit"
    class="logate-chat-interaction-form__submit"
    [ngClass]="{
      'logate-chat-interaction-form__submit--active': message.valid,
      'logate-chat-interaction-form__submit--inactive': message.invalid
    }"
  >
    <span class="logate-chat-interaction-form__submit--icon"> </span>
  </button>

  <emoji-mart
    (emojiSelect)="addEmoji($event)"
    *ngIf="isEmojiPickerVisible"
    [@openClose]
    emoji="point_up"
    class="logate-chat-interaction-form__emoji-mart"
    [isNative]="true"
    [showPreview]="false"
    title="{{ 'interaction.form.emoji' | translate }}"
  >
  </emoji-mart>
</form>
