import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SessionService } from './session.service';
import { SocketConnectionService } from './socket-connection.service';
import { Message } from '../../chat/interaction/models/message.model';
import { ContactService } from './contact.service';
import { LocalStorage } from '../enums/local-storage.enum';
import { InteractionEventType } from 'app/chat/interaction/models/interaction-event-type';

@Injectable()
export class MessageService {
  resetUnreadMessages$ = new BehaviorSubject<boolean>(false);
  messages$ = new BehaviorSubject<Message[]>(
    this.convertLocalStorageToMessage() ?? []
  );

  destroy$ = new Subject<void>();

  TIME_FOR_DELIVERY_STATUS = 2 /**s */ * 1000 /**ms */;

  constructor(
    private socketConnectionService: SocketConnectionService,
    private contactService: ContactService,
    private sessionService: SessionService
  ) {}

  watchForIncomingMessages(resetUnreadMessages = true): Observable<any> {
    this.destroy$ = new Subject<void>();
    if (resetUnreadMessages) {
      this.resetUnreadMessages$.next(false);
    }
    return this.socketConnectionService
      .watchMessages()
      .pipe(takeUntil(this.destroy$))
      .pipe(
        map((message) => JSON.parse(message.body)),
        map((message) =>
          this.createMessage(message.message, message.externalId)
        ),
        tap((message) => this.updateMessages(message))
      );
  }

  convertLocalStorageToMessage() {
    this.resetUnreadMessages$.next(true);
    if (localStorage.getItem(LocalStorage.LOGATE_CHAT_MESSAGES)) {
      return JSON.parse(
        localStorage.getItem(LocalStorage.LOGATE_CHAT_MESSAGES)
      ).map((message) => {
        const convertedMessage = new Message({
          deliveryStatus: message.deliveryStatus,
          externalId: message?.externalId,
          content: message.content,
          direction: message.direction,
          date: new Date(message.date),
        });
        return convertedMessage;
      });
    }
  }

  resendMessage(message: Message) {
    // first step is to delete message from messages$ and localStorage
    this.deleteMessage(message);
    // send again as regular message
    this.sendMessage(message);
  }

  deleteMessage(message: Message) {
    const messageIndex = this.messages$
      .getValue()
      .findIndex((m) => m.externalId === message.externalId);
    if (messageIndex === -1) {
      return;
    }

    const messages = this.messages$.getValue();
    messages.splice(messageIndex, 1);
    this.messages$.next(messages);
    localStorage.setItem(
      LocalStorage.LOGATE_CHAT_MESSAGES,
      JSON.stringify(this.messages$.getValue())
    );
  }

  sendMessage(message: Message): void {
    const request = {
      externalId: message.externalId,
      message: message.content,
      chatSessionId: this.sessionService.getChatSessionId(),
      time: message.date,
      // time: message.getFormattedDate(),
    };

    const headers = {
      externalId: request.externalId,
      chatSessionId: this.sessionService.getChatSessionId(),
    };
    if (this.sessionService.getChatSessionId()) {
      this.socketConnectionService.publishMessage(request, headers);
      this.updateMessages(message);
    }
  }

  checkDeliveryStatus = (message: Message) => {
    // if there is no still delivery status to message, set it to MESSAGE_UNDELIVERABLE
    const messageInStore = this.messages$
      .getValue()
      .find((m) => m.externalId === message.externalId);
    // if there is no message in store, return
    if (!messageInStore) {
      return;
    }
    // if there is already delivery status, return
    if (messageInStore.deliveryStatus) {
      return;
    }

    this.setDeliveryStatus(
      message.externalId,
      InteractionEventType.MESSAGE_UNDELIVERABLE
    );
  };

  updateMessages(message: Message): void {
    message = this.removeContactInfoFromMessage(message);
    // check if there is already message with same externalID
    if (
      !this.existingExternalId(this.messages$.getValue(), message.externalId)
    ) {
      this.messages$.next([...this.messages$.getValue(), message]);
      localStorage.setItem(
        LocalStorage.LOGATE_CHAT_MESSAGES,
        JSON.stringify(this.messages$.getValue())
      );
    }
    if (message.direction === 'IN') {
      setTimeout(
        this.checkDeliveryStatus,
        this.TIME_FOR_DELIVERY_STATUS,
        message
      );
    }
  }

  existingExternalId(messages: Message[], externalId: string) {
    return messages.some((m) => m.externalId === externalId);
  }

  getNumberOfMessages(): Observable<number> {
    return this.messages$.pipe(map((messages) => messages.length));
  }

  private createMessage(
    content: string,
    externalId: string,
    direction: 'IN' | 'OUT' = 'OUT'
  ): Message {
    return new Message({
      externalId,
      content,
      direction,
      date: new Date(),
    });
  }

  public setDeliveryStatus(
    externalId: string,
    deliveryStatus: InteractionEventType
  ) {
    // find external id in messages array
    const messages = this.messages$.getValue();
    const messageIndex = this.messages$
      .getValue()
      .findIndex((message) => message.externalId === externalId);
    if (messageIndex === -1) {
      return;
    }
    messages[messageIndex].deliveryStatus = deliveryStatus;
    this.messages$.next(messages);
    localStorage.setItem(
      LocalStorage.LOGATE_CHAT_MESSAGES,
      JSON.stringify(messages)
    );
  }

  resetMessages(): void {
    this.messages$.next([]);
    localStorage.setItem(LocalStorage.LOGATE_CHAT_MESSAGES, JSON.stringify([]));
  }

  /** In first message we are sending contact info to operator. We need to remove it before show it to client */
  removeContactInfoFromMessage(message: Message): Message {
    if (this.messages$.getValue().length === 0) {
      message.content = message.content.replace(
        this.contactService.parseContactInfo(),
        ''
      );
    }

    return message;
  }

  destroyService() {
    this.messages$.next([]);
    this.resetUnreadMessages$.next(false);
    localStorage.setItem(LocalStorage.LOGATE_CHAT_MESSAGES, JSON.stringify([]));
    this.destroy$.next();
    this.destroy$.complete();
  }
}
