<div class="logate-chat-customer-experience-recommend customer-experience__box">
  <div
    class="logate-chat-customer-experience__title"
    [style.font-family]="(configService.config$ | async)?.fontFamily"
  >
    {{ "customer-experience.recommend.title" | translate }}
  </div>
  <div
    class="logate-chat-circle-container"
    [style.font-family]="(configService.config$ | async)?.fontFamily"
  >
    <div *ngFor="let i of [3, 4, 5, 6, 7, 8, 9, 10, 1, 2]">
      <div
        class="logate-chat-item"
        [ngClass]="{ 'logate-chat-item--checked': i === selectedItem }"
        (click)="itemSelected(i)"
      >
        <span class="logate-chat-item--check" *ngIf="selectedItem === i"></span>
        {{ i }}
      </div>
    </div>

    <div class="logate-chat-circle-text">
      <div>{{ "customer-experience.recommend.info0" | translate }}</div>
      <div>{{ "customer-experience.recommend.info1" | translate }}</div>
    </div>
  </div>
</div>
