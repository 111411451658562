import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Message } from '../models/message.model';
import { MessageService } from '../../../core/services/message.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UnreadMessageService } from '../../../core/services/unread-message.service';
import { InteractionStateService } from '../services/interaction-state.service';
import { SocketConnectionService } from 'app/core/services/socket-connection.service';
import { ConfigService } from 'app/core/services/config.service';

@Component({
  selector: 'cc-chat-interaction-messages',
  templateUrl: './interaction-messages.component.html',
  styleUrls: ['./interaction-messages.component.scss'],
})
export class InteractionMessagesComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('messagesElement') private messageElementRef: ElementRef;

  messages$: Observable<Message[]>;

  destroy$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    public unreadMessageService: UnreadMessageService,
    public interactionStateService: InteractionStateService,
    private socketConnectionService: SocketConnectionService,
    public configService: ConfigService
  ) {
    this.socketConnectionService.establishWebSocketConnection();
  }

  ngOnInit(): void {
    this.watchForMessages();
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  watchForMessages(): void {
    this.messages$ = this.messageService.messages$
      .asObservable()
      .pipe(tap(() => setTimeout(() => this.scrollToBottom())));
  }

  scrollToBottom(): void {
    try {
      this.messageElementRef.nativeElement.scrollTop =
        this.messageElementRef.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
