<div class="logate-chat-customer-experience__box cx-rating">
  <div
    class="logate-chat-customer-experience__title"
    [style.font-family]="(configService.config$ | async)?.fontFamily"
  >
    {{ "customer-experience.rating.title" | translate }}
  </div>

  <div class="logate-chat-cx-rating__items">
    <div
      *ngFor="let i of [1, 2, 3, 4, 5]"
      class="logate-chat-cx-rating__item"
      (click)="onSelect(i)"
      [ngClass]="{
        'logate-chat-cx-rating__item--selected': selectedItem === i
      }"
    >
      <span
        class="logate-chat-cx-rating__item--check"
        *ngIf="selectedItem === i"
      ></span>
      {{ i }}
    </div>
  </div>

  <div
    class="logate-chat-cx-rating__footer"
    [style.font-family]="(configService.config$ | async)?.fontFamily"
  >
    <span>{{ "customer-experience.rating.info0" | translate }}</span>
    <span>{{ "customer-experience.rating.info1" | translate }}</span>
  </div>
</div>
