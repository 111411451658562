<div
  class="logate-chat-interaction-message"
  [ngClass]="'logate-chat-interaction-message__' + message.direction"
>
  <div
    *ngIf="message.direction === 'OUT'"
    class="logate-chat-interaction-message__icon"
    [ngStyle]="(configService.config$ | async)?.operatorLogoCss"
  ></div>

  <div>
    <div class="logate-chat-interaction-message-with-resend">
      <div
        *ngIf="
          deliveryStatus === InteractionEventType.MESSAGE_REJECTED ||
          deliveryStatus === InteractionEventType.MESSAGE_UNDELIVERABLE
        "
        class="logate-chat-resend-message"
        (click)="resendMessage()"
      ></div>
      <div
        class="logate-chat-unsuccessful logate-chat-interaction-message__content"
        [ngClass]="
          'logate-chat-interaction-message__content--' + message.direction
        "
        [style.font-family]="(configService.config$ | async)?.fontFamily"
        [ngStyle]="{
          background:
            message.direction === 'IN'
              ? (configService.config$ | async)?.color
              : ''
        }"
      >
        <span> {{ message.content }}</span>
      </div>
    </div>
    <div
      *ngIf="message.direction === 'IN'"
      class="logate-chat-message-date-info"
    >
      {{ message.getFormattedTime() }}
      <div
        *ngIf="deliveryStatus === InteractionEventType.MESSAGE_ACCEPTED"
        class="logate-chat-delivery-status logate-chat-delivery-status__sent"
      ></div>
      <div
        *ngIf="deliveryStatus === InteractionEventType.MESSAGE_DELIVERED"
        class="logate-chat-delivery-status logate-chat-delivery-status__delivered"
      ></div>
    </div>
  </div>
</div>
