import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfigService } from 'app/core/services/config.service';

@Component({
  selector: 'cc-chat-customer-experience-rating',
  templateUrl: './customer-experience-rating.component.html',
  styleUrls: ['./customer-experience-rating.component.scss'],
})
export class CustomerExperienceRatingComponent implements OnInit {
  selectedItem: number;
  @Output() selected = new EventEmitter<number>();

  constructor(public configService: ConfigService) {}

  ngOnInit(): void {}

  onSelect(item: number) {
    this.selectedItem = item;
    this.selected.emit(item);
  }
}
